import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";
//import dayjs from "dayjs";

export class SaleService{
    formDataSale = {};
    constructor() {
        this.formDataSale = {}
    }

    async getSale(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.sale.sale, {id: id, mode: mode})
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, sale: response.data.sale};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', sale: null};
            });
    }

    async saveSale(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.sale.saleSave, this.formDataSale).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.sale.saleSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', sale_id: null, sale: null};
                }
            }
            return {isSuccess: response.data.status === 'success', message: response.data.msg, sale_id: response.data.id, sale: response.data.sale};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', sale_id: null, sale: null};
        });
    }

    async deleteSale(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.sale.saleDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}